<template>
	<div style="display:block" class="layer">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav nav--fit">
						<div class="nav__wrap">
							<a href="#" class="nav__btn nav__btn--prev">
								<i class="icon icon-close">쿠폰 적용내역 닫기</i>
							</a>
							<p class="nav__item">
								<span class="nav__text">
									쿠폰 적용내역
								</span>
							</p>
							<a href="#" class="nav__btn">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<section class="layer-coupon">
						<div class="centered centered--large">
							<div class="centered__wrap">
								선택된 쿠폰이 없습니다
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>